<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ user !== undefined && user.id !== null ? "Modifier utilisateur" : "Nouvel administrateur" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="user.name"
                    label="Nom"
                    :rules="[rules.name]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-select
                    v-model="user.role_id"
                    :items="$store.getters['role/roles']"
                    item-text="label"
                    item-value="id"
                    label="Role"
                    type="text"
                    :rules="[rules.role_id]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="user.phone_number"
                    label="Téléphone"
                    type="number"
                    :rules="[rules.phone_number]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="user.country_code"
                    label="Code pays"
                    :rules="[rules.country_code]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="user.birthday"
                        label="Date de naissance"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="user.birthday"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      color="primary"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="user.lang"
                    :items="selectLang"
                    label="Langue"
                    :rules="[rules.lang]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="user.civility"
                    :items="selectOptions"
                    label="Civilité"
                    :rules="[rules.civility]"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="user.bio"
                    label="Biographie"
                    placeholder="Biographie"
                    hide-details="auto"
                    class="mb-2"
                    :rules="[rules.bio]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="user.preference"
                    label="Préférences"
                    :rules="[rules.password_confirmation_required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="user.password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Mot de passe"
                    placeholder="Mot de passe"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    hide-details="auto"
                    class="mb-2"
                    :rules="[rules.password_required]"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="user.password_confirmation"
                    :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                    label="Confirmer mot de passe"
                    :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :rules="[rules.password_confirmation_required]"
                    @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ user.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getCurrentInstance, ref, watch, onMounted,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'UserFormDialog',
  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: null,
  },

  setup(props) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const userForm = {
      id: null,
      name: null,
      email: null,
      birthday: null,
      lang: null,
      role_id: null,
      civility: null,
      password: null,
      password_confirmation: null,
      country_code: null,
    }
    const rules = {
      password_required: true,
      password_confirmation_required: true,
      email: true,
      phone_number: true,
      lang: true,
      civility: true,
      name: true,
      role_id: true,
      country_code: true,
    }

    const selectOptions = [
      { text: 'Monsieur', value: 'M.' },
      { text: 'Madame', value: 'Mme' },
      { text: 'Sa Majesté', value: 'SM' },
      { text: 'Son Excellence', value: 'SE' },
      { text: 'Docteur', value: 'Dr' },
      { text: 'Professeur', value: 'Pr' },
    ]

    const selectLang = [
      { text: 'Français', value: 'fr' },
      { text: 'English', value: 'en' },
    ]

    const instance = getCurrentInstance()

    watch(menu, val => {
      // eslint-disable-next-line no-return-assign,no-unused-expressions
      // val && setTimeout(() => (picker.value.activePicker = 'YEAR'))
    })

    const save = d => {
      menuref.value.save(d)
    }

    const close = () => {
      isAlertVisible.value = false
      message.value = ''
      rules.civility = true
      rules.name = true
      rules.lang = true
      rules.email = true
      rules.country_code = true
      rules.password_confirmation_required = true
      rules.password_required = true
      rules.phone_number = true
      rules.role_id = true
      instance.emit('clickout')
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      if (props.user.id !== null) {
        store.dispatch('user/updateAdmin', props.user).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.password_required = error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : true
            rules.email = error.response.data.errors.email !== undefined ? error.response.data.errors.email[0] : true
            rules.name = error.response.data.errors.name !== undefined ? error.response.data.errors.name[0] : true
            rules.phone_number = error.response.data.errors.phone_number !== undefined ? error.response.data.errors.phone_number[0] : true
            rules.lang = error.response.data.errors.lang !== undefined ? error.response.data.errors.lang[0] : true
            rules.civility = error.response.data.errors.civility !== undefined ? error.response.data.errors.civility[0] : true
            rules.password_confirmation_required = error.response.data.errors.password_confirmation !== undefined ? error.response.data.errors.password_confirmation[0] : true
            rules.role_id = error.response.data.errors.role_id !== undefined ? error.response.data.errors.role_id[0] : true
            rules.country_code = error.response.data.errors.country_code !== undefined ? error.response.data.errors.country_code[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('user/addAdmin', props.user).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.password_required = error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : true
            rules.email = error.response.data.errors.email !== undefined ? error.response.data.errors.email[0] : true
            rules.phone_number = error.response.data.errors.phone_number !== undefined ? error.response.data.errors.phone_number[0] : true
            rules.lang = error.response.data.errors.lang !== undefined ? error.response.data.errors.lang[0] : true
            rules.civility = error.response.data.errors.civility !== undefined ? error.response.data.errors.civility[0] : true
            rules.password_confirmation_required = error.response.data.errors.password_confirmation !== undefined ? error.response.data.errors.password_confirmation[0] : true
            rules.role_id = error.response.data.errors.role_id !== undefined ? error.response.data.errors.role_id[0] : true
            rules.country_code = error.response.data.errors.country_code !== undefined ? error.response.data.errors.country_code[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
      instance.emit('submitted')
    }

    watch(() => props.user.id, () => {
      store.dispatch('user/hasMenuAccess', 'VIEWROLES').then(response => {
        if (response.result === true) {
          store.state.role.field.to_store = true
          store.state.role.field.paginate = 50
          store.dispatch('role/getRoleList', { page: 1, field: store.state.role.field }).catch(error => {
            console.log(error)
          })
        }
      })
    }, { deep: true })

    onMounted(() => {
      store.dispatch('user/hasMenuAccess', 'VIEWROLES').then(response => {
        if (response === true) {
          store.state.role.field.to_store = true
          store.state.role.field.paginate = 50
          store.dispatch('role/getRoleList', { page: 1, field: store.state.role.field }).catch(error => {
            console.log(error)
          })
        }
      })
    })

    return {
      selectLang,
      selectOptions,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      userForm,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      save,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
